import { LoaderFunction, LoaderFunctionArgs } from '@remix-run/node';
import { json, useLoaderData } from '@remix-run/react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const ogDefaultImage = '@images/og-default.png';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  imageDescription?: string;
  prefix?: string;
  type?: string;
  url?: string;
  extraTags?: string;
  noindex?: string;
  nofollow?: string;
}

const SEO = ({
  title,
  description,
  image = '',
  imageDescription = '',
  prefix = 'og: http://ogp.me/ns#',
  type = 'website',
  url, //url slug (always start with /)
  extraTags = undefined,
  noindex,
  nofollow,
}: SEOProps) => {
  // TODO: canonicalSiteUrl move to .env
  const siteUrl = 'https://localhost:3000';
  const lang = 'en';
  const locale = 'en_NZ';
  const siteTitle = 'KiwiPlates';
  const twitterUsername = '@kiwiplates';

  const imageUrl = `${image}?w=1080&h=1080&fit=crop&format=jpg`;

  const htmlAttributes = {
    lang,
    prefix,
  };

  const robotsNoindex = noindex === 'yes';
  const robotsNofollow = nofollow === 'yes';

  let robots = '';
  let hasRobots = false;
  if (robotsNofollow || robotsNoindex) {
    hasRobots = true;
    if (robotsNoindex && robotsNofollow) robots = 'nofollow noindex';
    else if (robotsNoindex) robots = 'noindex';
    else robots = 'nofollow';
  }
  if (siteUrl.toLowerCase() != 'https://www.kiwiplates.nz') {
    hasRobots = true;
    robots = 'nofollow noindex';
  }

  return (
    <Fragment>
      <Helmet htmlAttributes={htmlAttributes} defer={false}>
        <script
          src="https://js.afterpay.com/afterpay-1.x.js"
          data-analytics-enabled
          async
        ></script>
        <title>{title}</title>
        <meta name="description" content={description} />
        {hasRobots && <meta name="robots" content={robots} />}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={twitterUsername} />
        <meta name="twitter:creator" content={twitterUsername} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:url" content={`${siteUrl}${url}`} />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={siteTitle} />
      </Helmet>
      {image && image.length ? (
        <Helmet>
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:alt" content={imageDescription} />
          <meta property="og:image:width" content="1080" />
          <meta property="og:image:height" content="1080" />
        </Helmet>
      ) : (
        <Helmet>
          <meta property="og:image" content={ogDefaultImage} />
          <meta property="og:image:alt" content="" />
          <meta property="og:image:width" content="1080" />
          <meta property="og:image:height" content="1080" />
        </Helmet>
      )}
      {extraTags && extraTags.length ? <Helmet>{extraTags}</Helmet> : null}
    </Fragment>
  );
};

export default SEO;
